import React from "react";
import ownerLogo from "../images/Ownership_Logo_White.svg"
import vitaliteLogo from "../images/logo-vitalite2020.png"
import medaviLogo from "../images/medavie-logo.png"
import nursingLogo from "../images/NBANH_horizontal.png"
import {Link} from "gatsby"

export default function FooterEN() {
    return (<>
            <div className={"w100 h100-lg footer mt6"}>
                <div className={"container-lg mb6"}>
                    <hr/>
                    <div className={"r-c-lg jsb ac-lg mt5 afs"}>
                        <div className={"c information"}>
                            <p className={"mb0 tac-sm"}><b>GJ HOPKINS | LACY OFFICES</b></p>
                            <p className={"mb0 tac-sm"}>714 5th Street, NE | Roanoke, VA 24016</p>
                            <p className={"mb0 tac-sm"}>Phone: <a href={"tel:5409821873"}>(540) 982-1873</a> | Fax:
                                (540) 982-0655</p>
                            <p className={"mb0 tac-sm"}>1809 Broadway Street | Charlottesville, VA 22902</p>
                            <p className={"mb0 tac-sm"}>Phone: <a href={"tel:4342967542"}>(434) 296-7542</a> | Fax:
                                (434) 293-9073</p>
                            <p className={"mb0 tac-sm"}>VA Contractors Lic. #2701 004928A</p>

                        </div>
                        <img  className={"my5-lg"} src={ownerLogo} height={"100"} alt={"Employee Owned"}/>

                        <div className={"c afs jfs ac-lg w100-lg right-side"}>
                            <p className={"mb3"}> © 2021 GJ HOPKINS | LACY | A BRANCH GROUP COMPANY</p>
                            <div className={"r-c-sm jfe jc-lg w100"}>
                                <Link className={"pr3 tac-sm my2-sm"} target={"_blank"}
                                      to="https://www.gjhopkins.com/about-us/our-history/">
                                    About Us
                                </Link>
                                <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                                      to="https://www.gjhopkins.com/privacy-policy/">
                                    Privacy
                                </Link>
                                <Link className={"pl3 tac-sm my2-sm"} target={"_blank"}
                                      to="https://www.gjhopkins.com/contact/">
                                    Contact
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={"r jc-lg "}>
                        <p className={"py3 vidcruiter "}><Link className={"c-green"} target={"_blank"}
                                                               to={"https://vidcruiter.com/"}>Powered
                            by VidCruiter</Link></p>
                    </div>
                </div>
            </div>
        </>
    );
}