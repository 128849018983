import gjLogo from "../images/GJ Logo.png";
import React from "react";
import {Link} from "gatsby"
import Helmet from "react-helmet";
import SEO from "./seo";

export default function HeaderEN(props) {
    return (<>
        <Helmet
            bodyAttributes={{
                class: 'en-page'
            }}
        />
        <SEO description="GJ Hopkins Hiring Platform" title="GJ Hopkins Hiring Platform"/>
        <div className={"container-lg"}>
            <svg width="1408px" className={"w100 top-decal mb0-lg"} viewBox="0 0 1408 154" version="1.1"
                 xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="MAP" transform="translate(-258.000000, 0.000000)" fill="#6A1D32" fill-rule="nonzero">
                        <g id="Unknown" transform="translate(258.000000, 0.000000)">
                            <polygon id="Path" fill-opacity="0.5" points="704 154 1408 0 0 0"></polygon>
                            <polygon id="Path" points="704 108 1408 1 0 1"></polygon>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <div className={"container-lg"}>
            <div className={"r-c-lg ac jsb"}>
                <Link to="/" className={"gtm-logo"}><img className={"my4"} width="286" src={gjLogo} alt="GJ Hopkins Logo"/></Link>
                <div className={"c jc afe ac-lg right-message"}>
                    <h2 className={"tar tac-lg mb2-lg"}><b>Welcome to the GJ Hopkins | Lacy</b></h2>
                    <h2 className={"tar tac-lg mb3-lg"}>Hiring Platform</h2>
                </div>
            </div>
            <hr className={"mb5 mt3"}/>
        </div>
    </>)
}